/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}


//***************Estilos personalizados Reliabytics*****************
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
//!
@import 'https://js.arcgis.com/4.27/@arcgis/core/assets/esri/themes/light/main.css';

//**** NAV principal en Componentes
.customNav {
  padding-left: 16px;
  padding-right: 16px;

}

.customPr-0 {
  padding-right: 0px;
}

.customTreeParent {
  margin-right: 0px !important;
  width: 96%!important;
}

.customIconNav {
  margin-left: 5px;
  margin-right: 5px;
}
.custom-MB-img{
  margin-bottom: 0.4rem;
}

.padding-0 {
  padding: 0rem !important;
}

.width-100 {
  width: 100% !important;
}

.width-75 {
  width: 75% !important;
}

//******** GRID y Treeview Synfusion
.statustxt.e-riesgoBajoColor {
  color: #00cc00;
  position: relative;
}

.statustxt.e-riesgoMedioColor {
  color: #FDE055;
  position: relative;
}

.statustxt.e-riesgoMedioAltoColor {
  color: #FE9946;
  position: relative;
}

.statustxt.e-riesgoAltoColor {
  color: #e60000;
  position: relative;
}

.statustxt.e-sinRiesgoColor {
  color: #ebebeb;
  position: relative;
}

.statustemp {
  position: relative;
  height: 19px;
  border-radius: 5px;
  text-align: center !important;
}

.statustemp.e-riesgoBajoColor {
  background-color: #ccffcc;
  width: 90px;
}

.statustemp.e-riesgoMedioColor {
  background-color: #ffffcc;
  width: 90px;
}

.statustemp.e-riesgoMedioAltoColor {
  background-color: #ffe6cc;
  width: 90px;
}

.statustemp.e-riesgoAltoColor {
  background-color: #ffd7cc;
  width: 90px;
}

.statustemp.e-sinRiesgoColor {
  background-color: #f8f8f8;
  width: 90px;
}

// tamano de letras en tree grid
.e-grid .e-headercelldiv {
  font-size: 11px !important;
}

.e-grid .e-rowcell {
  font-size: 11px !important;
}

.e-grid .e-summarycell {
  font-size: 11px !important;
}

//Colores fondo y texto dialogo y grid
.e-riesgoBajoColorTxt {
  color: #00cc00;
}

.e-riesgoMedioColorTxt {
  color: #FFC000;
}

.e-riesgoMedioAltoColorTxt {
  color: #FE9946;
}

.e-riesgoAltoColorTxt {
  color: #e60000;
}

.e-sinRiesgoColorTxt {
  color: #b3aeae;
}

.e-riesgoBajoColorFon {
  background-color: #ccffcc;
}

.e-riesgoMedioColorFon {
  background-color: #ffffcc;
}

.e-riesgoMedioAltoColorFon {
  background-color: #ffe6cc;
}

.e-riesgoAltoColorFon {
  background-color: #ffd7cc;
}

.e-sinRiesgoColorFon {
  //background-color: #f8f8f8;
  background-color: #ffffff;
}

.e-inputRiesgo {
  padding: 0.25rem 0.5rem;
  font-size: 0.7109375rem
}


//******** Color de Botones Generales
.btn-azul-reliab {
  color: #FFFFFF;
  background-color:#154f87;
  border-color:#154f87;
}

.btn-verde-reliab {
  color: #FFFFFF;
  background-color:#56cc4a;
  border-color:#56cc4a;
}

.btn-gris-reliab {
  color: #FFFFFF;
  background-color:#999999;
  border-color:#999999;
}



//************ Estilos Tabs Custom
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap{
  margin-top: 0;
  color: #495057;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap{
  background: #154f87;
  border-radius: 0px;
}
//*********

#nestedViewerExtensionButton {
  background-image: url(https://img.icons8.com/color/64/000000/picture-in-picture.png);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  filter: grayscale(100%);
}

#nestedViewerExtensionButton.active {
  filter: none;
}

#nestedViewerExtensionDropdown {
  background: #eee;
  color: #333;
}

body::-webkit-scrollbar {
  width: 10px;
  background: #2a3042;
}

body::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, .2);
  border-radius: 10px;
  border-right: 2px solid #2a3042;

}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.5);
  border-radius: 8px;
}
